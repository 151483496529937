import React from "react";
import {useScreen} from "@/util/useScreen";
import {CHAIN_ID, isBlast, PERPS_VIEW, TEAM_WHITELIST} from "@/util/constants";
import {useAccount} from "wagmi";
import {BsBank, BsFillLightningChargeFill} from "react-icons/bs";
import {
  MdAdminPanelSettings,
  MdCollections,
  MdLeaderboard,
  MdOutlineControlPointDuplicate, MdQueryStats
} from "react-icons/md";
import {BiSolidBank} from "react-icons/bi";
import {AiFillBank} from "react-icons/ai";

export interface HeaderLink {
  name: string;
  to: string;
  id: number;
  isAuth: boolean;
  disabled?: boolean;
  color?: string;
  icon?: React.ReactNode;
}

export const useHeaderLinks = () => {
  const isPerpsView = PERPS_VIEW;
  const { address, chain } = useAccount();

  const storedAuthStatus = typeof window !== "undefined" && localStorage !== undefined && localStorage?.getItem("isLoggedIn") === "true";

  const links: HeaderLink[] = isPerpsView
    ? [
      { name: "Trade", to: "/", id: 100, isAuth: false, icon: <BsFillLightningChargeFill /> },
      { name: "Earn", to: "/vaults", id: 101, isAuth: false, icon: <AiFillBank /> },
      { name: "Collect", to: "/collect", id: 103, isAuth: false, disabled: !isBlast, icon: <MdCollections /> },
      { name: "Points", to: "/points", id: 102, isAuth: false, icon: <MdLeaderboard /> },
      { name: "Activity", to: "/activity", id: 106, isAuth: false, disabled: false, icon: <MdQueryStats /> },
      { name: "Gib Gold", to: "/gibgold", id: 104, isAuth: false, color: "#FCFC05", disabled: !isBlast, icon: <img src="/static/BlastGoldIcon.svg" alt="gibgold" className="w-4 h-4"/> },
      { name: "Admin", to: storedAuthStatus ? "/admin/dashboard" : "/admin/login", id: 105, isAuth: false, disabled: !address || !TEAM_WHITELIST.includes(address), icon: <MdAdminPanelSettings />},
    ] : [
      { name: "TRADE", to: "/collections", id: 5, isAuth: false },
      { name: "EARN", to: "/pool/new", id: 4, isAuth: false },
      { name: "PORTFOLIO", to: "/portfolio", id: 2, isAuth: true },
      ...(CHAIN_ID === 1 ? [] : [{ name: "BUY NFTS", to: "/buyNFTs", id: 3, isAuth: true }]),
    ];

  return links;
}