import {ConnectButton} from "@rainbow-me/rainbowkit";
import {Button, ButtonType} from "@/components/Button";
import classNames from "classnames";
import React, {useContext, useEffect} from "react";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {formatEther, prettifyNumber} from "@/util/converters";
import {useAccount} from "wagmi";
import {CHAIN_ID, PERPS_VIEW} from "@/util/constants";
import {googlePushDataLayer} from "@/util/analytics";
import {Modal, useModal} from "@/components/Modal";
import {SignAgreement} from "@/components/whitelist/SignAgreement";
import {saveUserReferral} from "@/api/datafetcher";
import { useWhitelist } from "@/components/whitelist/useWhitelist";
import {BsFillLightningChargeFill} from "react-icons/bs";
import {savePerpUserReferral} from "@/api/perpsDataFetcher";
import { disconnect } from '@wagmi/core'
import {config} from "../config";
import {useScreen} from "@/util/useScreen";
import {twMerge} from "tailwind-merge";

export interface Props {
  className?: string;
}

export const ConnectWallet: React.FunctionComponent<Props> = ({className = ''}) => {
  const isDesktop = useScreen('md');
  const {balance} = useContext(UserBalanceContext);
  const {address} = useAccount();

  useEffect(() => {
    googlePushDataLayer('connectedAddress', {
      'address': address,
    });

    const isPerpsView = PERPS_VIEW;
    const referencedKey = `referred_${address}`;
    const code = localStorage.getItem("ref");
    const referenced = localStorage.getItem(referencedKey);

    const perpReferencedKey = `perp_referred_${address}`;
    const perpCode = localStorage.getItem("perp_ref");
    const perpReferenced = localStorage.getItem(perpReferencedKey);

    if (referenced !== "true" && (address && code)) {
      saveUserReferral(address, code).then((r) => {
        if (r.success) {
          localStorage.setItem(referencedKey, "true");
        }
      });
    }

    if (isPerpsView && address && perpCode) {
      savePerpUserReferral(address, perpCode).then((r) => {
        if (r.success) {
          localStorage.setItem(perpReferencedKey, "true");
        }
      });
    }

  }, [address])

  const whitelist = useWhitelist();
  const signatureModal = useModal();

  useEffect(() => {
    if (address && whitelist.data === "NEEDS_TO_SIGN" && !signatureModal.modalVisible) {
      signatureModal.show();
    }
  }, [whitelist.data, address, signatureModal.modalVisible]);

  const onCancel = async () => {
    if (address) {
      await disconnect(config);
    }
    signatureModal.hide();
  }

  const onSuccess = async () => {
    await whitelist.refetch();
    signatureModal.hide();
  }

  return (
    <div>
      <Modal {...signatureModal} controlled={true}>
        <SignAgreement onCancel={onCancel} onSuccess={onSuccess} />
      </Modal>
      <ConnectButton.Custom>
        {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus ||
              authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected || chain.unsupported) {
                  return (
                    <Button
                      onClick={!connected ? openConnectModal : openChainModal}
                      buttonType={ButtonType.PRIMARY}
                      className={twMerge(classNames({
                        "p-2": isDesktop
                      }), className || '')}
                    >
                      <BsFillLightningChargeFill/> {
                          !connected
                            ? `Connect${isDesktop ? " Wallet" : ""}`
                            : "Change network" }
                    </Button>
                  );
                }

                return (
                  <div style={{ display: "flex", gap: 12 }}>
                    <Button
                      onClick={openAccountModal}
                      buttonType={ButtonType.SECONDARY}
                      className={className || ''}
                    >
                      <div className="flex gap-2 items-center font-medium">
                        <div className={classNames("rounded-full overflow-hide w-2 h-2 bg-call pulse-green-animate")}></div>
                        Connected
                      </div>
                      {/*{account.displayName}*/}
                      { balance ? ` (${prettifyNumber(formatEther(balance))} ${CHAIN_ID === 1 ? "ETH" : "DETH"})` : ""}
                    </Button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
}
