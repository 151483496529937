import Link from "next/link";
import {
  PRIVACY_POLICY_LINK, RESPONSIBLE_DISCLOSURE_LINK,
  TERMS_OF_USE_LINK,
  isBlast
} from "@/util/constants";
import React from "react";
import {FaEthereum} from "react-icons/fa";
import Image from "next/image";
import {FooterLogo} from "@/components/icons/FooterLogo";
import {useLongView} from "@/components/BNPL/LongContext";
import {LongFooter} from "@/components/BNPL/LongFooter";
import {useOptionAction} from "@/components/Option/actions/OptionActionContext";
import {OptionActionFooter} from "@/components/Option/actions/OptionActionFooter";
import {useEthPrice} from "@/contexts/EthPriceContext";
import Switch from "react-switch";
import {theme} from "../../tailwind.config";
import classNames from "classnames";
import {NewsButton} from "@/components/news/NewsButton";
import {useFooterLinks} from "@/hooks/useFooterLinks";
import {GasDisplay} from "@/components/Footer/GasDisplay";

const renderLegalLink = (label: string, href: string) => {
  return <a href={href}
            target="_blank"
            className="text-neutral-content/50 font-light hover:text-neutral-content text-sm">
    {label}
  </a>
}

const renderLinkWithText = (id: string, href: string, image: string | React.ReactNode, inNewTab = true): React.ReactNode => {
  if (typeof image === 'string') {
    image = <Image src={image as string} alt={id} width={14} height={14} color="white" className="hover:fill-white text-neutral-content" />;
  }
  return (
    <Link key={`footer_${id}`} href={href} target={inNewTab ? "_blank" : undefined} className="text-xl flex flex-row items-center">
      <span data-tip={id.toUpperCase()}
            className="text-neutral-content hover:text-white hover:scale-110 flex tooltip">{ image }</span>
    </Link>
  );
}

export const Footer = () => {
  const {ethPrice, showInUsd, setShowInUsd} = useEthPrice();
  const footerLinks = useFooterLinks();

  const bnpl = useLongView();
  const optionAction = useOptionAction();
  return (
    <div className="flex flex-col">
      { bnpl?.data && <LongFooter {...bnpl.data} /> }
      { optionAction?.data?.option && <OptionActionFooter {...optionAction.data} /> }
      <footer className="bg-main-bg border-t border-neutral-content/50 flex flex-col xl:flex-row xl:items-center xl:justify-between h-full w-full p-2 gap-4" id="footer">
        <div className="responsive-flex gap-2 md:gap-6">
          {renderLinkWithText("home", "/home", <FooterLogo/>, false)}
          {renderLegalLink("Privacy Policy", PRIVACY_POLICY_LINK)}
          {renderLegalLink("Terms and Conditions", TERMS_OF_USE_LINK)}
          {renderLegalLink("Responsible Disclosure Policy", RESPONSIBLE_DISCLOSURE_LINK)}
        </div>
        <div className="responsive-flex gap-6">
          <div className="flex flex-row gap-6">
            {/*<BlastinPepesWhitelist />*/}
            <NewsButton/>
            {
              ethPrice &&
              <div className="flex flex-row gap-2 items-center">
                <FaEthereum className={classNames({
                  'text-neutral-content': showInUsd,
                  'text-white': !showInUsd
                })}/>
                <Switch checked={showInUsd}
                        onColor={theme.extend.colors.neutral}
                        checkedIcon={false}
                        height={16}
                        width={32}
                        handleDiameter={16}
                        uncheckedIcon={false}
                        onChange={e => setShowInUsd(e.valueOf())}/>
                <span className={classNames({
                  'text-neutral-content': !showInUsd,
                  'text-white': showInUsd
                })}>$</span>
              </div>
            }
            <GasDisplay />
          </div>
          <div className="flex flex-row gap-6 pr-2">
            {footerLinks.map(({id, href, icon, inNewTab}) => renderLinkWithText(id, href, icon, inNewTab))}
          </div>
          {
            isBlast &&
            <div className="h-full flex flex-col justify-end lg:items-end brightness-100">
              <img id="badge-button" className="w-[128px] h-[28px]" src="/static/built_on_blast.svg" alt="Built on Blast"/>
            </div>
          }
        </div>
      </footer>
    </div>
  );
}
