/** @type {import('tailwindcss').Config} */
const defaultTheme = require("tailwindcss/defaultTheme");
const headerHeight = "80px";

module.exports = {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./layouts/**/*.{js,ts,jsx,tsx}",
    "./modules/**/*.{js,ts,jsx,tsx}",
    "node_modules/daisyui/dist/**/*.js",
  ],
  theme: {
    extend: {

      fontFamily: {
        sans: ["Inter"],
        display: ["Inter"],
        body: ["Inter"],
        inter: ["Inter"],
      },
      // height: {
      //   header: headerHeight,
      // },
      colors: {
        "main-bg": "#090F17",

        glass: "hsla(0,0%,100%,.05)",
        "glass-focus": "hsla(0,0%,100%,.2)",
        "call": "#31D582",
        "wasabigreen": "#94ff0b",
        "wasabiyellow": "#d5b431",
        "put": "#EB5D2A",
        "base-100": "#020e21",
        "base-200": "#04193b",
        "base-300": "#062454",
        "base-400": "#082f6e",
        "base-900": "#1062e6",
        "base-content": "",
        neutral: "#344054",
        "neutral-focus": "#697d99",
        "blastyellow": "#FCFC05",
      },
      // padding: {
      //   header: headerHeight,
      // },
    },
  },
  daisyui: {
    themes: [
      {
        dark: {
          ...require("daisyui/src/colors/themes")["[data-theme=dark]"],
          primary: "#94ff0b",
          "primary-content": "#ffffff",
          "base-content": "#ffffff",
          "base-100": "#020e21",
          "base-200": "#04193b",
          "base-300": "#062454",
          "base-400": "#082f6e",
          neutral: "#344054",
          "neutral-focus": "#697d99",
          "neutral-content": "#98a2b3",
        },
        light: {
          ...require("daisyui/src/colors/themes")["[data-theme=light]"],
          primary: "#94ff0b",
          "primary-content": "#ffffff",
          "base-content": "#ffffff",
          "base-100": "#020e21",
          "base-200": "#04193b",
          "base-300": "#062454",
          "base-400": "#082f6e",
          neutral: "#344054",
          "neutral-focus": "#697d99",
          "neutral-content": "#98a2b3",
        },
      },
    ],
  },
  plugins: [
    require("daisyui"),
    require("@tailwindcss/typography"),
    require("@tailwindcss/line-clamp"),
    require('@codaworks/react-glow/tailwind'),
    require('tailwindcss-safe-area')
  ],
};