import {useGasPrice} from "wagmi";
import {useHasMounted} from "@/hooks/useHasMounted";
import {formatGwei} from "viem";
import {FaGasPump} from "react-icons/fa";
import React from "react";

export const GasDisplay = () => {
  const {data: gasData} = useGasPrice();
  const hasMounted = useHasMounted();

  if (!hasMounted && gasData === undefined) {
    return null;
  }

  const gas = Number(formatGwei(gasData || 0n));
  let decimals: number;
  if (gas > 10) {
    decimals = 1;
  } else if (gas > 0.1) {
    decimals = 2;
  } else {
    decimals = 3;
  }

  return (
    <span className="text-neutral-content/70 hover:text-white text-sm flex flex-row items-center gap-2 select-none">
      <FaGasPump />
      {`| ${gas.toFixed(decimals)} Gwei`}
    </span>
  )
}